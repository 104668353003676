<template>
  <div id="addTaxRegime" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">RÉGIMEN FISCAL</v-row>
      <v-container fluid class="addCategory2">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" xs="6" class="marginCol">
            <v-row>
              <div class="form-group mb-2">
                <label for="idRegime">Id Régimen Fiscal</label>
                <input
                  type="text"
                  name="idRegime"
                  id="idRegime"
                  placeholder="Id de Periodicidad"
                  class="inputs"
                  autocomplete="false"
                  v-model="idRegime"
                  required
                  maxlength="20"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="description" class="mr-12">Descripción</label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Descripción de la Periodicidad"
                  class="ml-9 inputs"
                  autocomplete="false"
                  v-model="description"
                  required
                  maxlength="25"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="use" class="mr-12">Persona Física</label>
                <select
                  type="text"
                  name="use"
                  id="use"
                  class="ml-4 inputs"
                  style="width: 172px !important"
                  v-model="fisica"
                  required
                >
                  <option disabled value>Seleccione</option>
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-2">
                <label for="moral" class="mr-12">Persona Moral</label>
                <select
                  type="text"
                  name="moral"
                  id="moral"
                  class="ml-4 inputs"
                  style="width: 172px !important"
                  v-model="moral"
                  required
                >
                  <option disabled value>Seleccione</option>
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6" align-self="center">
            <v-row justify="center">
              <button
                class="botonAmarillo"
                style="float: none"
                @click="validacion()"
              >
                Guardar
              </button>
              <router-link to="/TaxRegime" id="backRegime">
                <button class="botonAmarilloDerecho">Regresar</button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Régimen Fiscal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el régimen fiscal?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Régimen Fiscal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
import Loader from "@/components/Loader";
export default {
  components: {
    Sidebar,
    Sesion,
    Loader,
  },
  data() {
    return {
      search: "",
      show: false,
      dialog: false,
      expiration: false,
      description: "",
      idRegime: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      fisica: "0",
      moral: "0",
    };
  },
  methods: {
    guardar() {
      /*console.log("fisica", parseInt(this.fisica));
      console.log("moral", parseInt(this.moral));*/
      this.advice = false;
      this.respuesta = "El régimen fiscal fue guardado con éxito";
      this.confirmation = true;
      this.show = true;
      axios
        .post(
          Server + "/regimenFiscal",
          {
            ClaveCFDI: this.idRegime,
            TipoRegimen: this.description,
            PersonaFisica: parseInt(this.fisica) == 1 ? true : false,
            PersonaMoral: parseInt(this.moral) == 1 ? true : false,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.idRegime = "";
          this.fisica = 0;
          this.moral = 0;
          this.description = "";
          this.respuesta = "El régimen fiscal fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idRegime = "";
            this.fisica = 0;
            this.moral = 0;
            this.description = "";
            this.respuesta = "El régimen fiscal no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validacion: function () {
      this.errors = [];
      if (this.idRegime == " " || this.idRegime == 0 || this.idRegime == "") {
        this.errors.push("Se debe especificar el id de la periodicidad.");
      }
      if (this.description == "" || this.description == " ") {
        this.errors.push("Se debe especificar la descripción del régimen.");
      }
      /* if (this.fisica == "") {
        this.errors.push("Se debe especificar el uso de la persona física.");
      }
      if (this.moral == "") {
        this.errors.push("Se debe especificar el uso de la persona moral.");
      } */
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      this.idRegime = "";
      this.description = "";
      this.fisica = 0;
      this.moral = 0;
      this.advice = false;
    },
    aux() {
      this.confirmation = false;
      this.idRegime = "";
      this.description = "";
      this.fisica = 0;
      this.moral = 0;
      this.$router.push("/taxRegime");
    },
  },
  created() {},
};
</script>